import React from 'react'

const Persona = () => {
  return (
    <section className="wrapper">
      <h1>Persona</h1>
    </section>
  )
}

export default Persona